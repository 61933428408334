import defaultAuthor from "@/constants/defaultAuthor";
import siteInfo from "@/constants/siteInfo";
// import { useWindowSize } from "@/hooks/useIsMobile";
import dayjs from "dayjs";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import SVG from "react-inlinesvg";
import { defaultAuthorMap } from "@/utils/langCodeMapper";
import sendGAEvent from "@/utils/gaEvent";
import Image from "next/image";
// const AdSlot300x250 = dynamic(() => import("@/component/Ads/AdSlot300x250"));
const LivePlayerCard = dynamic(() => import("@/component/LivePlayerCard"));

const FirstCard = ({ content, isLCP = false, categoryTitle = "", isResultsPage=false }) => {
  const defaultAuthorState =
    content?.langcode &&
    content?.langcode in defaultAuthorMap &&
    defaultAuthorMap[content?.langcode];
  const authorLink = content?.author?.[0]?.canonical_url
    ? content?.author?.[0]?.canonical_url
    : defaultAuthorState
    ? defaultAuthorState?.link
    : defaultAuthor.canonical_url;
  const authorTitle =
    (content?.author?.[0]?.title ||
      (content?.author_detail?.[0]?.title !== "mediology"
        ? content?.author_detail?.[0]?.title
        : defaultAuthorState?.name) ||
      defaultAuthorState?.name) ??
    defaultAuthor.title;
  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex-shrink-0">
        <div className="relative">
          <a
            href={content?.canonical_url ?? ""}
            target={content?.publishtime ? "_blank" : "_self"}
            onClick={() => {
              let eventName = `Home Screen - ${categoryTitle}`;
              if(!isResultsPage){
                sendGAEvent({
                  eventName: eventName,
                  pageTitle: document?.title,
                  pageLocation: window?.location?.href,
                });
              }
            }}
          >
            <Image
              className="w-full   rounded-md"
              src={`${
                content?.image_small || content?.thumb_image
              }?size=360:203`}
             
              title={content?.image_title}
              alt={
                content?.image_title ? content?.image_title : siteInfo?.siteName
              }
              fetchpriority={ "high" }
              loading={ "eager"  }
              priority
              role="button"
              width="360" // Fixed width
              height={Math.floor((9 / 16) * 360)} // Maintain 16:9 aspect ratio for height
              style={{ aspectRatio: "16/9" }}
              // height="203px"
              // width="360px"
              // unoptimized={ true }
            />
          </a>
          {/* Conditionally render the play icon if the content type is video */}
          {content?.content_type === "video" && (
            <a
              href={content?.canonical_url ?? ""}
              target={content?.publishtime ? "_blank" : "_self"}
            >
              <div
                role="button"
                className="absolute inset-0 flex justify-center items-center"
              >
                <SVG
                  className="w-10 h-10"
                  src="/assets/icons/svg/play-media.svg"
                />
              </div>
            </a>
          )}
        </div>
      </div>

      <div className="py-2">
        <a
          href={content?.canonical_url ?? ""}
          target={content?.publishtime ? "_blank" : "_self"}
          onClick={() => {
            let eventName = `Home Screen - ${categoryTitle}`;
            if(!isResultsPage){
              sendGAEvent({
                eventName: eventName,
                pageTitle: document?.title,
                pageLocation: window?.location?.href,
              });
            }
          }}
        >
          <h3
            className="text-lg font-extrabold line-clamp-3 hover:text-primary"
            dangerouslySetInnerHTML={{
              __html: `${content?.title_short || content?.title}`,
            }}
          />
        </a>
        <p className="text-xs text-gray-500 font-extralight mt-1 mb:text-sm xs:text-sm">
          <img
            className="h-[16px] w-[16px] inline-block mr-1"
            alt="read video icon"
            loading="lazy"
            height={16}
            width={16}
            src={
              content?.content_type === "video"
                ? "/assets/icons/svg/watchType.svg"
                : "/assets/icons/svg/readType.svg"
            }
          />
          <time dateTime={content?.datetime_published}>
            {dayjs(content?.datetime_published).format("MMM DD YYYY")}
          </time>{" "}
          · By{" "}
          <a
            href={authorLink}
            target={content?.publishtime ? "_blank" : "_self"}
          >
            <span className="text-primary">{authorTitle}</span>
          </a>
        </p>
      </div>
    </div>
  );
};

const StandardCard = ({ content, isVideoCard = false, categoryTitle = "", isResultsPage=false }) => {
  const router = useRouter();
  const isHomeVideoCard = router?.asPath === "/" && isVideoCard;
  const defaultAuthorState =
    content?.langcode &&
    content?.langcode in defaultAuthorMap &&
    defaultAuthorMap[content?.langcode];
  const authorLink = content?.author?.[0]?.canonical_url
    ? content?.author?.[0]?.canonical_url
    : defaultAuthorState
    ? defaultAuthorState?.link
    : defaultAuthor.canonical_url;
  const authorTitle =
    (content?.author?.[0]?.title ||
      content?.author_detail?.[0]?.title ||
      defaultAuthorState?.name) ??
    defaultAuthor.title;
  return (
    <div className="flex rounded-sm overflow-hidden">
      <div>
        <div className="relative">
          <a
            href={content?.canonical_url ?? ""}
            target={content?.publishtime ? "_blank" : "_self"}
            onClick={() => {
              let eventName = `Home Screen - ${categoryTitle}`;
              if(!isResultsPage){
                sendGAEvent({
                  eventName: eventName,
                  pageTitle: document?.title,
                  pageLocation: window?.location?.href,
                });
              }
              
            }}
          >
            <img
              className="min-w-[114px] object-fill rounded-md xs:mt-[2px]"
              src={`${
                content?.image_small || content?.thumb_image
              }?size=320:180`}
              title={content?.image_title}
              alt={
                content?.image_title ? content?.image_title : siteInfo?.siteName
              }
              loading={"lazy"}
              role="button"
              width="150"
              height={Math.floor((9/16)*150)}
            />
          </a>
          {/* Conditionally render the play icon if the content type is video */}
          {content?.content_type === "video" && (
            <a
              href={content?.canonical_url ?? ""}
              target={content?.publishtime ? "_blank" : "_self"}
            >
              <div
                role="button"
                className="absolute inset-0 flex justify-center items-center"
              >
                <SVG
                  className="w-8 h-8"
                  src="/assets/icons/svg/play-media.svg"
                />
              </div>
            </a>
          )}
        </div>
      </div>
      <div className="pl-2 flex-1">
        <a
          href={content?.canonical_url ?? ""}
          target={content?.publishtime ? "_blank" : "_self"}
          onClick={() => {
            let eventName = `Home Screen - ${categoryTitle}`;
            if(!isResultsPage){
              sendGAEvent({
                eventName: eventName,
                pageTitle: document?.title,
                pageLocation: window?.location?.href,
              });
            }
            
          }}
        >
          <h3
            className="text-md font-bold hover:text-primary line-clamp-3"
            dangerouslySetInnerHTML={{
              __html: `${content?.title_short || content?.title}`,
            }}
          />
        </a>
        <p className="text-xs text-gray-500 font-extralight mt-1 line-clamp-2 mb:text-sm xs:text-sm">
          {content?.content_type !== "video" ? (
            <img
              className="h-[16px] w-[16px] inline-block mr-1"
              height={16}
              loading="lazy"
              width={16}
              alt="read video icon"
              src={"/assets/icons/svg/readType.svg"}
            />
          ) : (
            !isHomeVideoCard && (
              <img
                className="h-[16px] w-[16px] inline-block mr-1"
                height={16}
                loading="lazy"
                width={16}
                alt="read video icon"
                src={"/assets/icons/svg/watchType.svg"}
              />
            )
          )}
          <time dateTime={content?.datetime_published || content?.datetime}>
            {dayjs(content?.datetime_published || content?.datetime).format(
              "MMM DD YYYY"
            )}
          </time>{" "}
          · By{" "}
          <a
            href={authorLink}
            target={content?.publishtime ? "_blank" : "_self"}
          >
            <span className="text-primary">{authorTitle}</span>
          </a>
        </p>
      </div>
    </div>
  );
};





const NewsGrid = ({
  newsData = null,
  isLCP = false,
  showHeader = true,
  showAd = false,
  liveVideoData = null,
  isMobileDevice = false,
  showLiveVideo = false,
  isAutoLiveBlog = false,
  isResultsPage=false
}) => {
  // const size = useWindowSize();
  return (
    <div className="my-2">
      {showHeader && (
        <div className="flex my-2 items-center">
          <div className="text-primary font-bold text-lg sm:text-xl xs:text-xl md:text-xl">
            {/* {newsData?.info?.icon && <SVG
            className="w-4 h-4 inline mr-2"
            src={newsData?.info?.icon}
          />} */}
            <h2>{isResultsPage?"":newsData?.info?.name ?? "रिजल्ट लाईव् अपडेट्स"}</h2>
          </div>
          <div className="text-slate-500 font-normal text-sm ml-auto">
            {(newsData?.info?.canonical_url || isAutoLiveBlog)  && (
              <a
                href={  isAutoLiveBlog
                  ? "/election/delhi-assembly/constituency-list#election-auto-live-blogs"
                  :newsData?.info?.canonical_url}
                target={newsData?.publishtime ? "_blank" : "_self"}
                onClick={() => {
                  let eventName = `Home Screen - ${
                    newsData?.info?.name ?? "न्यूज़"
                  } View More`;
                  if(!isResultsPage){
                    sendGAEvent({
                      eventName: eventName,
                      pageTitle: document?.title,
                      pageLocation: window?.location?.href,
                    });
                  }  
                }}
              >
                <span className="flex items-center">
                  और देखें
                  <SVG
                    className="w-4 h-4 inline ml-2  mt-[-2px]"
                    src="/assets/icons/svg/rightArrowRound.svg"
                  />
                </span>
              </a>
            )}
          </div>
        </div>
      )}

      <div className="flex flex-col md:flex-row gap-2 md:max-h-[28rem]">
        <div className="w-full md:w-1/2">
          {newsData?.content[0] && (
            <>
              <FirstCard
                content={newsData?.content[0]}
                isLCP={isLCP}
                categoryTitle={newsData?.info?.name ??
                  (isAutoLiveBlog ? "लाईव् अपडेट्स" : "न्यूज़")}
                  isResultsPage={isResultsPage}
              />
              {/* {size.width !== undefined && size.width < 900 && showAd && (
                <AdSlot300x250
                  divId="div-gpt-ad-1659434453897-0"
                  minWidth="300px"
                  minHeight="250px"
                />
              )} */}
              {/* {showAd && (
                <AdSlot300x250
                  divId="div-gpt-ad-1659434453897-0"
                  minWidth="300px"
                  minHeight="250px"
                />
              )} */}
            </>
          )}
          {newsData?.content[1] && (
            <div className="my-2">
              <StandardCard
                content={newsData?.content[1]}
                isVideoCard={newsData?.info?.canonical_url === "/videos"}
                categoryTitle={newsData?.info?.name ?? "न्यूज़"}
                isResultsPage={isResultsPage}
              />
              {showLiveVideo &&
                liveVideoData &&
                isMobileDevice &&
                liveVideoData?.live_id && (
                  <div className="my-2">
                    <LivePlayerCard height={240} liveData={liveVideoData} />
                  </div>
                )}
            </div>
          )}
        </div>

        <div
          className="w-full md:w-1/2 md:overflow-auto"
          style={{ height: "fit-content" }}
        >
          {newsData?.content?.slice(2)?.map((content, index) => (
            <div key={index}  className={`mb-2 ${isAutoLiveBlog && index > 3 ? "hidden" : ""}`}>
              <StandardCard
                content={content}
                isVideoCard={newsData?.info?.canonical_url === "/videos"}
                categoryTitle={newsData?.info?.name ?? "न्यूज़"}
                isResultsPage={isResultsPage}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsGrid;
